/*mobile*/

$mobile: 850px;
$tablet: 1200px;

/*font*/

$font-family: "Roboto";
$secondary-font-family: "Montserrat";
$font-size: 13px;
$title-size: 13px;
$font-size-icon: 18px;

/*colors*/

$primary-color: #ff8300;
$primary-color-hover: #e67603;
$red: #d33f49;
$green: #5eb56c;
$black: #000;
$font-color: #fff;
$font-color-dark: #333;
$border-color: #2f333f;
$background-color: #333;
$background-color-secondary: #444;
$background-color-light: #5a5a5a;

/*spacing*/

$space-unit: 8px;
$space: 8px;
$space-xs: calc(0.5 * #{$space-unit}); // 4px
$space-sm: calc(0.75 * #{$space-unit}); // 6px
$space-md: calc(1.5 * #{$space-unit}); // 12px
$space-lg: calc(2 * #{$space-unit}); // 16px
$space-xl: calc(3 * #{$space-unit}); // 24px
$border-radius: 3px;

/* border-radius */

$rounded-none: 0;
$rounded: 0.375rem;
$rounded-full: 9999px;

@import "styles/core/color";
@import "styles/components/switch";
@import "styles/components/section";
@import "styles/components/tabs";
@import "styles/components/modal";
@import "styles/components/spin";
@import "styles/components/settings";

/*global*/

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: $font-family;
  font-size: $font-size;
  color: $font-color;
  // zoom: 80%;
}

input,
textarea,
button,
select {
  font-size: $font-size;
  font-family: $font-family;
  outline: 0;
}

select option {
  color: $black;
}

@media (max-width: $mobile) {
  select,
  textarea,
  input {
    font-size: 14px !important;
  }
}

@mixin button-style {
  padding: $space * 2;
  color: #fff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: 0;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

@mixin button-dark {
  background-color: rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

@mixin button-large {
  min-width: 100px;
  float: right;
  padding: $space;
  font-size: 10px;
  font-weight: 600;
  color: $font-color;
  font-family: "Montserrat";
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  border-radius: $border-radius;
  background-color: $primary-color;
  cursor: pointer;

  &:disabled {
    background-color: #e5e5e5;
    color: $font-color-dark;
    opacity: 0.3;
    cursor: default;
  }

  &.delete {
    background-color: $red;
  }

  &.small {
    min-width: auto;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 9px;
  }
}

@mixin table {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: auto;

  th {
    white-space: nowrap;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background: #df7300;
  }

  &.long {
    max-height: 600px;
  }

  table {
    width: 100%;
    border-radius: $border-radius;
    overflow: hidden;

    &.clickable {
      tr {
        outline: 0;
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover,
        &.selected {
          background-color: rgba(0, 0, 0, 0.1);
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    .infinity {
      font-size: 24px;
    }

    .td-row-button {
      padding: 5px !important;

      button {
        padding: 5px 10px !important;
        white-space: nowrap;
        background-color: $background-color-light !important;
        font-size: 12px;
        border-radius: 12px;

        &:disabled {
          background-color: $primary-color !important;
          cursor: pointer;
        }

        &.selected {
          background-color: $primary-color !important;
          cursor: pointer;
        }
      }
    }

    img {
      max-width: 100px;
    }

    tr {
      &.selected {
        border-left: 5px solid $primary-color;
      }

      &.selectable {
        cursor: pointer;
      }
    }

    th {
      padding: $space;
      text-align: left;
      background-color: rgba(0, 0, 0, 0.3);

      &.no-padding {
        padding: 0;
      }

      &.sorted {
        text-decoration: underline;
      }

      &.selectable {
        cursor: pointer;
      }
    }

    td {
      padding: $space;
      background-color: rgba(0, 0, 0, 0.2);
      vertical-align: middle;

      &.no-padding {
        padding: 0;
      }
    }

    th,
    td {
      a,
      button {
        @include button-style;
        padding: $space;

        &:hover {
          background-color: unset;
        }
      }

      &.button {
        cursor: pointer;
      }

      &.center {
        text-align: center;
      }

      .div-dot {
        margin: auto;
      }
    }

    &.small {
      th,
      td {
        padding: 4px / 2 $space;

        button {
          padding: 0 $space-xs;
        }
      }
    }
  }

  &.small {
    th,
    td {
      padding: 4px $space !important;
    }
  }

  label {
    display: block;
    margin-bottom: $space;
    font-size: 11px;
    letter-spacing: 1px;
    font-family: "Montserrat";
    text-transform: uppercase;
    font-weight: 600;

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 $space * 2;
      line-height: 36px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: $border-radius;
    }
  }

  .td-large {
    width: 100%;
  }

  .td-no-wrap {
    white-space: nowrap;
  }

  .td-right {
    text-align: right;
  }
}

@mixin label {
  display: block;
  margin-bottom: $space;
  font-size: 11px;
  letter-spacing: 1px;
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 600;

  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 $space * 2;
    line-height: 36px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: $border-radius;
  }
}

@mixin inputs {
  width: 100%;
  padding: $space;
  font-family: $font-family;
  color: $font-color-dark;
  background-color: #f0f0f0;
  border: none;
  border-radius: $border-radius;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.5;
  }

  &:read-only {
    cursor: default;
  }
}

/* splitters */

.div-split {
  position: relative;
  width: $space;
  opacity: 0;
  visibility: hidden;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom right,
      #333 0%,
      #333 50%,
      transparent 50%
    );
  }

  &.before {
    &:after {
      background-image: linear-gradient(
        to top left,
        #333 0%,
        #333 50%,
        #444 50%
      );
    }
  }
}

/* Admin */

.div-admin {
  flex: 1;
  display: flex;
  background-color: $background-color-light;

  .div-crud {
    .div-header {
      box-shadow: none;
    }

    .div-form {
      background-color: #555;
    }
  }

  table {
    width: 100%;
    border-radius: $border-radius;
    background-color: $background-color-secondary;
    overflow: hidden;

    &.clickable {
      tr {
        outline: 0;
        line-height: 42px;
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }

        &.selected {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }

    th {
      padding: 0 $space;
      text-align: left;
      background-color: $background-color;
    }

    td {
      padding: 0 $space;
    }
  }

  .div-list {
    width: 300px;
    display: flex;
    flex-direction: column;
    background-color: $background-color-secondary;
    overflow-x: auto;

    &.no-scroll {
      overflow: hidden;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $primary-color;
    }

    .div-header {
      flex-shrink: 0;
      display: flex;
      background-color: $background-color;

      form {
        flex: 1;
        display: flex;
      }

      input {
        flex: 1;
        padding: $space * 2;
        background-color: rgba(0, 0, 0, 0.1);
        border: none;
        color: #fff;
      }

      button {
        @include button-style;
      }
    }

    .div-item {
      padding: $space;
      outline: 0;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }

      &.selected {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .div-table {
      flex: 1;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: $primary-color;
      }

      &.small {
        table {
          td {
            padding: 2px $space !important;
          }
        }
      }
    }

    .div-pager {
      display: flex;
      background-color: $background-color;

      button {
        @include button-style;

        &.disabled {
          opacity: 0.2;
        }
      }

      .div-pages {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        letter-spacing: 1px;
        font-family: "Montserrat";
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }

  .div-table {
    padding: $space-xl;
    box-sizing: border-box;
  }

  &.full-heigh {
    height: 100%;

    .div-crud {
      height: 100%;
    }

    .div-table {
      height: calc(100% - 42px);
    }
  }
}

/* CRUD */

.div-crud {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: $background-color-secondary;
  height: 100%;
  max-height: 100%;

  .div-header {
    display: flex;
    width: 100%;
    line-height: 42px;
    font-size: 11px;
    letter-spacing: 1px;
    font-family: "Montserrat";
    text-transform: uppercase;
    font-weight: 600;
    background-color: $background-color;
    box-sizing: border-box;
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);

    .div-title {
      flex: 1;
      padding: 0 $space * 2;
    }

    button {
      @include button-style;
      @include button-dark;
    }

    .div-search {
      display: flex;
      align-items: center;

      & > * {
        margin-right: $space * 2;
      }

      & > button {
        margin-right: 0;
      }
    }
  }

  .div-form {
    flex: 1;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $primary-color;
    }
  }

  form,
  .div-in-form {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    min-height: min-content;
    padding: $space;
    box-sizing: border-box;
    max-width: 850px;
    margin: auto;

    &.div-form-full {
      max-width: unset;
    }

    .div-field {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      margin-top: $space * 2;
      padding: $space;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: $primary-color;
      }

      &.large {
        flex-basis: 100%;
        max-width: 100%;
        // overflow-x: auto;
      }

      &.command {
        justify-content: flex-end;
        align-items: flex-start;
      }

      @media (max-width: $mobile) {
        flex-basis: 100%;
      }

      .label-error {
        background-color: $red;
        padding: 10px;
      }

      .btn-large {
        min-width: 100px;
        float: right;
        padding: $space;
        font-size: 11px;
        font-weight: 600;
        color: $font-color;
        font-family: "Montserrat";
        text-transform: uppercase;
        letter-spacing: 1px;
        border: none;
        border-radius: $border-radius;
        background-color: $primary-color;
        cursor: pointer;

        &:disabled {
          background-color: #e5e5e5;
          color: $font-color-dark;
          opacity: 0.3;
          cursor: default;
        }

        &.delete {
          background-color: $red;
        }
      }
    }

    .div-sub {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: $space;
      border-radius: $border-radius;
      overflow: hidden;

      &.no-space {
        margin-bottom: -$space;
      }

      .div-toolbar {
        width: 100%;
        display: flex;
        cursor: pointer;

        .div-title {
          flex: 1;
          display: flex;
          align-items: center;
          padding: $space;
          font-size: 11px;
          letter-spacing: 1px;
          font-family: "Montserrat";
          text-transform: uppercase;
          font-weight: 600;
          background-color: rgba(0, 0, 0, 0.3);
        }

        button {
          @include button-style;
          @include button-dark;
        }
      }

      .div-content {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        min-height: min-content;
        box-sizing: border-box;
        padding: $space;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .btn {
      &.large {
        width: 100%;
        padding: $space;
        font-size: 11px;
        font-weight: 600;
        color: $font-color;
        font-family: "Montserrat";
        text-transform: uppercase;
        letter-spacing: 1px;
        border: none;
        border-radius: $border-radius;
        background-color: $primary-color;
        cursor: pointer;

        &:disabled {
          background-color: #e5e5e5;
          color: $font-color-dark;
          opacity: 0.3;
          cursor: default;
        }
      }

      &.small {
        padding: $space;
        border: none;
        color: #fff;
        font-size: 11px;
        font-weight: 600;
        white-space: nowrap;
        color: $font-color;
        font-family: "Montserrat";
        text-transform: uppercase;
        letter-spacing: 1px;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        background-color: $primary-color;
        cursor: pointer;
      }

      &:disabled {
        background-color: $background-color-light;
        opacity: 0.7;
        cursor: not-allowed;
      }
    }

    .div-section {
      margin-top: $space * 3;
      margin-left: -$space;
      margin-right: -$space;
    }

    .div-group {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      flex-basis: 100%;
    }

    label {
      @include label();
    }

    input[type="text"],
    input[type="date"],
    input[type="datetime-local"],
    input[type="number"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    select {
      @include inputs();
    }

    .div-divider {
      display: flex;
      justify-content: space-between;

      &.spaced {
        input {
          margin-right: $space;
        }

        button {
          border-radius: $border-radius;
        }
      }

      input {
        flex-basis: 48%;

        &.large {
          flex: 1;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }
    }

    .div-checkbox {
      display: flex;
      border-radius: $border-radius;
      overflow: hidden;

      button {
        flex: 1;
        padding: $space;
        background-color: #e5e5e5;
        color: $font-color-dark;
        font-size: 11px;
        font-weight: 600;
        font-family: "Montserrat";
        text-transform: uppercase;
        letter-spacing: 1px;
        border: none;
        opacity: 0.3;
        cursor: pointer;

        &.selected {
          background-color: $primary-color;
          color: #fff;
          opacity: 1;
        }
      }
    }

    .div-avatar {
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: $border-radius;

      .div-selected {
        width: 100px;
        margin: $space;
        margin-right: 0px;
        border-radius: $border-radius;

        img {
          width: 100px;
          border-radius: $border-radius;
        }
      }

      .div-gallery {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: $space;

        .div-search {
          display: flex;
          width: 100%;
          border-radius: $border-radius;
          overflow: hidden;

          input {
            width: 100%;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
          }

          button {
            @include button-style;
            @include button-dark;
          }
        }

        .div-results {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: $space;
          padding: $space-xs;
          background-color: rgba(0, 0, 0, 0.2);

          .div-result {
            width: 85px;
            height: 85px;
            margin: $space-xs;
            border-radius: $border-radius;
            background-size: cover;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
              opacity: 0.5;
            }
          }
        }

        .div-pager {
          display: flex;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: $border-radius;

          button {
            @include button-style;

            &:disabled {
              opacity: 0.2;
            }
          }

          .div-pages {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 11px;
            letter-spacing: 1px;
            font-family: "Montserrat";
            text-transform: uppercase;
            font-weight: 600;
          }
        }
      }
    }

    .div-list {
      flex: 1;
      min-height: 34px;
      max-height: 150px;
      padding: $space 0;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: $border-radius;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: #df7300;
      }

      .div-item {
        padding: $space;
        user-select: none;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }

    .div-table {
      @include table;
    }

    table {
      width: 100%;
      border-radius: $border-radius;
      overflow: hidden;

      &.small {
        td {
          padding: $space-xs $space;
        }
      }

      &.clickable {
        tr {
          outline: 0;
          transition: all 0.3s ease;
          cursor: pointer;

          &:hover,
          &.selected {
            background-color: rgba(0, 0, 0, 0.1);
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }

      .infinity {
        font-size: 24px;
      }

      .td-row-button {
        padding: 5px !important;

        button {
          padding: 5px 10px !important;
          white-space: nowrap;
          background-color: $background-color-light !important;
          font-size: 12px;
          border-radius: 12px;

          &:disabled {
            background-color: $primary-color !important;
            cursor: pointer;
          }

          &.selected {
            background-color: $primary-color !important;
            cursor: pointer;
          }
        }
      }

      img {
        max-width: 100px;
      }

      tr {
        &.selected {
          border-left: 5px solid $primary-color;
        }
      }

      th {
        padding: $space;
        text-align: left;
        background-color: rgba(0, 0, 0, 0.3);

        &.no-padding {
          padding: 0;
        }

        &.sorted {
          text-decoration: underline;
        }

        &.selectable {
          cursor: pointer;
        }
      }

      td {
        padding: $space;
        background-color: rgba(0, 0, 0, 0.2);
        vertical-align: middle;

        &.no-padding {
          padding: 0;
        }
      }

      th,
      td {
        a,
        button {
          @include button-style;
        }

        &.button {
          cursor: pointer;
        }
      }
    }

    .div-file {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      border-radius: $border-radius;
      overflow: hidden;

      input[type="text"] {
        border-radius: 0px;
      }

      input[type="file"] {
        z-index: 2;
        opacity: 0;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .div-content {
        flex: 1;
        // width: 100%;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: rgba(0, 0, 0, 0.2);
        padding: $space;

        &.empty {
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }

    .div-status {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: $space 0;
      padding: $space;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: $border-radius;

      &.empty {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .div-commands {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin: $space * 2 $space;

      &.edit {
        justify-content: space-between;
      }

      &.add {
        & > * {
          margin-left: $space * 2;
        }
      }

      .div-commands-group {
        display: flex;
        justify-content: flex-start;
      }

      button {
        min-width: 100px;
        float: right;
        padding: $space;
        font-size: 11px;
        font-weight: 600;
        color: $font-color;
        font-family: "Montserrat";
        text-transform: uppercase;
        letter-spacing: 1px;
        border: none;
        border-radius: $border-radius;
        background-color: $primary-color;
        cursor: pointer;

        &:disabled {
          background-color: #e5e5e5;
          color: $font-color-dark;
          opacity: 0.3;
          cursor: default;
        }

        &.delete {
          background-color: $red;
        }

        &.neutral {
          background-color: $background-color;
        }
      }

      button + button {
        margin-left: $space;
      }
    }

    &.div-form-full {
      max-width: unset;

      .div-section {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

/* Errors */

.div-error,
.div-success,
.div-warning,
.div-request {
  z-index: 1100;
  flex-shrink: 0;
  display: flex;
  color: #fff;
  background-color: $red;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  &.small {
    margin-top: $space;
    padding: $space;
    border-radius: $border-radius;
  }

  .div-message {
    flex: 1;
    padding: $space * 2;
  }

  .div-icon {
    padding: $space * 2;
    background-color: rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}

.div-request,
.div-success {
  background-color: $green;
}

.div-warning {
  background-color: #ff840050;
}

.div-input {
  input[type="text"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="number"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  select {
    width: 100%;
    padding: $space;
    font-family: $font-family;
    color: $font-color-dark;
    background-color: #f0f0f0;
    border: none;
    border-radius: $border-radius;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: all 0.3s ease;

    option {
      color: $black;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}

.div-search {
  input[type="text"],
  input[type="date"],
  input[type="number"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  select {
    padding: $space-xs $space;
  }
}

.span-download {
  cursor: pointer;
}

.div-field-alarm {
  display: flex;
  flex-direction: row;
  align-items: center;

  .div-alarm {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ($space * 2) - 4px;
    height: ($space * 2) - 4px;
    margin: 0 $space;
    border: 2px solid white;
    border-radius: 50%;
    letter-spacing: 0;
  }
}

.div-tables {
  padding: $space;

  &.horizontal {
    display: inline-flex;

    & > * {
      flex-grow: 1;
    }
  }

  &.vertical {
    display: flex;
    flex-direction: column;
  }

  .div-table {
    @include table;
    margin: $space;
    box-sizing: border-box;
    max-height: unset;
  }
}

// Alarms

.div-alarm-color-cell {
  display: flex;
  align-items: center;
}

.div-alarm-color {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ($space * 2) - 4px;
  height: ($space * 2) - 4px;
  margin: 0 $space;
  border: 2px solid white;
  border-radius: 50%;
  letter-spacing: 0;
}

.success {
  background-color: $green;
}

/* Dragular */

.no-click {
  pointer-events: none;
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.labels {
  color: #000000;
  // background-color: white;
  font-family: Roboto, Arial, sans-serif;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  // width: 40px;
  padding: 1px;
  box-sizing: border-box;
  white-space: nowrap;
  border-radius: 4px;
  z-index: 3000 !important;
}

.div-container {
  height: calc(100%);
  padding: $space-xl;
  box-sizing: border-box;

  .div-inline-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: $space;
    padding: $space 0;

    .div-inline-group {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: $space;
    }

    .div-field {
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;
      box-sizing: border-box;
      min-width: 25%;

      label {
        @include label();
      }

      input[type="text"],
      input[type="date"],
      input[type="datetime-local"],
      input[type="number"],
      input[type="password"],
      input[type="email"],
      input[type="tel"],
      select {
        @include inputs();
      }
    }

    .div-commands {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      gap: $space;

      button {
        @include button-large();
      }
    }
  }
}

.div-loading.centered {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  background: #df7300;
}

.vector {
  font-family: serif;
  font-style: italic;
  font-size: 13px;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
