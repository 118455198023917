app-settings {
  .div-items {
    padding-bottom: $space;
    background-color: $background-color;

    .div-item {
      display: flex;
      opacity: 0.3;
      background-color: $background-color-secondary;
      cursor: pointer;

      &.visible {
        opacity: 1;
        background-color: $background-color;
      }

      .div-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $space $space * 2;
        color: $primary-color;
      }

      .div-name {
        display: flex;
        align-items: center;
      }
    }

    .info {
      opacity: 1;
      background-color: $background-color;
      padding: $space-xs $space * 2;
      display: flex;
      justify-content: stretch;
      cursor: initial;

      .div-label,
      .div-data {
        flex-basis: 50%;
      }
    }

    .info-btn {
      opacity: 1;
      background-color: $background-color;
      padding-top: $space;

      button {
        color: #fff;
        background-color: $primary-color;
        padding: $space;
        border: none;
        cursor: pointer;
        outline: 0;
        transition: all 0.3s ease;
        flex-grow: 1;

        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }

    .updated {
      color: #fff;
      padding: $space $space * 2;
      background-color: $green;
    }

    .error,
    .success {
      color: #fff;
      padding: $space $space * 2;
    }

    .error {
      background-color: $red;
    }

    .div-title {
      display: block;
      margin-top: $space * 2;
      margin-bottom: $space;
      padding-left: $space;
      padding-bottom: $space;
      font-size: 11px;
      letter-spacing: 1px;
      font-family: "Montserrat";
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}
