.div-modal {
  z-index: 1300;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}

.div-frame {
  width: calc(100vw - 50px);
  margin: $space;
  background-color: $background-color-secondary;
  border-radius: $border-radius;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  &.full {
    height: 100vh;
    width: 100vw;
    margin: 0;
  }

  &.medium {
    width: 1000px;
    min-height: 500px;
  }

  &.small {
    width: 540px;
  }

  &.bordered-full {
    height: calc(100vh - 50px);
    width: calc(100vw - 50px);
  }

  &.narrow {
    max-width: 500px;
  }
}

.div-frame-alarm {
  width: calc(100vw - 20%);
  height: 750px;
}
