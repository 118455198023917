/* style */
$switch-width: 30px;
$switch-height: 18px;
$switch-padding: 3px;

/* animation */
$switch-animation-duration: 0.2s;

.checkbox {
  display: flex;
  padding: $space 0;
}

.switch {
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  width: $switch-width;
  height: $switch-height;
  margin-right: $space-md;
  padding: $switch-padding 0;
  border-radius: 50em;
}

.switch-input,
.switch-label {
  position: absolute;
  left: 0;
  top: 0;
}

.switch-input {
  margin: 0;
  padding: 0;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;

  &:checked + .switch-label {
    background-color: $primary-color;
  }

  &:checked + .switch-label + .switch-marker {
    left: calc(100% - #{$switch-height} + #{$switch-padding});
  }
}

.switch-label {
  width: 100%;
  height: 100%;
  color: transparent;
  user-select: none;
  background-color: $background-color-light;
  border-radius: inherit;
  z-index: 1;
  transition: background #{$switch-animation-duration};
}

.switch-marker {
  position: relative;
  background-color: #fff;
  width: calc(#{$switch-height} - #{$switch-padding} * 2);
  height: calc(#{$switch-height} - #{$switch-padding} * 2);
  border-radius: 50%;
  z-index: 2;
  pointer-events: none;
  box-shadow: 0 1px 1px hsla(0, 0%, 0%, 0.25);
  left: $switch-padding;
  transition: left #{$switch-animation-duration};
  will-change: left;
}
