.div-crud {
  .tab-wrapper {
    height: calc(100% - 47px);
  }
}

.tab-wrapper {
  .tab-header {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    height: $space * 4;
    border-bottom: 2px solid $primary-color;

    .tab-head {
      display: flex;
      align-items: center;
      padding: $space $space * 2;
      font-size: 11px;
      letter-spacing: 1px;
      font-family: "Montserrat";
      text-transform: uppercase;
      font-weight: 600;
      opacity: 0.5;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        opacity: 1;
        // background-color: $primary-color-hover;
      }

      &.selected {
        opacity: 1;
        background-color: $primary-color;
      }
    }
  }

  .tab-content {
    height: calc(100% - 34px); // 32px head + 2px border
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      background: #df7300;
    }
  }
}
