.div-section {
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  padding: $space * 2;
  font-size: 11px;
  letter-spacing: 1px;
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.2);

  .action {
    cursor: pointer;
  }
}
