// basics
$white: #ffffff;
$black: #3a3a3a;


// primary
$primary-050: #ffb566;
$primary-100: #ffa84d;
$primary-200: #ff9c33;
$primary-300: #ff8f1a;
$primary-400: #ff8300;
$primary-500: #e67600;
$primary-600: #cc6900;
$primary-700: #b35c00;
$primary-800: #994f00;
$primary-900: #804200;

// secondary
$secondary-050: #fdfdea;
$secondary-100: #fdf6b2;
$secondary-200: #fce96a;
$secondary-300: #faca15;
$secondary-400: #e3a008;
$secondary-500: #c27803;
$secondary-600: #9f580a;
$secondary-700: #8e4b10;
$secondary-800: #723b13;
$secondary-900: #633112;

// neutral
$neutral-050: #f9fafb;
$neutral-100: #f4f5f7;
$neutral-200: #e5e7eb;
$neutral-300: #d2d6dc;
$neutral-400: #9fa6b2;
$neutral-500: #6b7280;
$neutral-600: #4b5563;
$neutral-700: #374151;
$neutral-800: #252f3f;
$neutral-900: #161e2e;

// gray
$gray-050: #f9fafb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #9ca3af;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;
$gray-900: #111827;

// red
$red-050: #fef2f2;
$red-100: #fee2e2;
$red-200: #fecaca;
$red-300: #fca5a5;
$red-400: #f87171;
$red-500: #ef4444;
$red-600: #dc2626;
$red-700: #b91c1c;
$red-800: #991b1b;
$red-900: #7f1d1d;

// orange
$orange-050: #fff7ed;
$orange-100: #ffedd5;
$orange-200: #fed7aa;
$orange-300: #fdba74;
$orange-400: #fb923c;
$orange-500: #f97316;
$orange-600: #ea580c;
$orange-700: #c2410c;
$orange-800: #9a3412;
$orange-900: #7c2d12;

// yellow
$yellow-050: #fffbeb;
$yellow-100: #fef3c7;
$yellow-200: #fde68a;
$yellow-300: #fcd34d;
$yellow-400: #fbbf24;
$yellow-500: #f59e0b;
$yellow-600: #d97706;
$yellow-700: #b45309;
$yellow-800: #92400e;
$yellow-900: #78350f;

// green
$green-050: #ecfdf5;
$green-100: #d1fae5;
$green-200: #a7f3d0;
$green-300: #6ee7b7;
$green-400: #34d399;
$green-500: #10b981;
$green-600: #059669;
$green-700: #047857;
$green-800: #065f46;
$green-900: #064e3b;

// teal
$teal-050: #f0fdfa;
$teal-100: #ccfbf1;
$teal-200: #99f6e4;
$teal-300: #5eead4;
$teal-400: #2dd4bf;
$teal-500: #14b8a6;
$teal-600: #0d9488;
$teal-700: #0f766e;
$teal-800: #115e59;
$teal-900: #134e4a;

// cyan
$cyan-050: #ecfeff;
$cyan-100: #cffafe;
$cyan-200: #a5f3fc;
$cyan-300: #67e8f9;
$cyan-400: #22d3ee;
$cyan-500: #06b6d4;
$cyan-600: #0891b2;
$cyan-700: #0e7490;
$cyan-800: #155e75;
$cyan-900: #164e63;

// light-blue
$light-blue-050: #f0f9ff;
$light-blue-100: #e0f2fe;
$light-blue-200: #bae6fd;
$light-blue-300: #7dd3fc;
$light-blue-400: #38bdf8;
$light-blue-500: #0ea5e9;
$light-blue-600: #0284c7;
$light-blue-700: #0369a1;
$light-blue-800: #075985;
$light-blue-900: #0c4a6e;

// blue
$blue-050: #eff6ff;
$blue-100: #dbeafe;
$blue-200: #bfdbfe;
$blue-300: #93c5fd;
$blue-400: #60a5fa;
$blue-500: #3b82f6;
$blue-600: #2563eb;
$blue-700: #1d4ed8;
$blue-800: #1e40af;
$blue-900: #1e3a8a;

// indigo
$indigo-050: #eef2ff;
$indigo-100: #e0e7ff;
$indigo-200: #c7d2fe;
$indigo-300: #a5b4fc;
$indigo-400: #818cf8;
$indigo-500: #6366f1;
$indigo-600: #4f46e5;
$indigo-700: #4338ca;
$indigo-800: #3730a3;
$indigo-900: #312e81;

// purple
$purple-050: #f5f3ff;
$purple-100: #ede9fe;
$purple-200: #ddd6fe;
$purple-300: #c4b5fd;
$purple-400: #a78bfa;
$purple-500: #8b5cf6;
$purple-600: #7c3aed;
$purple-700: #6d28d9;
$purple-800: #5b21b6;
$purple-900: #4c1d95;

// pink
$pink-050: #fdf2f8;
$pink-100: #fce7f3;
$pink-200: #fbcfe8;
$pink-300: #f9a8d4;
$pink-400: #f472b6;
$pink-500: #ec4899;
$pink-600: #db2777;
$pink-700: #be185d;
$pink-800: #9d174d;
$pink-900: #831843;

// rose
$rose-050: #fff1f2;
$rose-100: #ffe4e6;
$rose-200: #fecdd3;
$rose-300: #fda4af;
$rose-400: #fb7185;
$rose-500: #f43f5e;
$rose-600: #e11d48;
$rose-700: #be123c;
$rose-800: #9f1239;
$rose-900: #881337;